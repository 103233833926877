import React from 'react';
import { Wrapper } from '../../components/Common';

type Props = {}

const AdditionalDetails = (props: Props) => {
  return (
    <Wrapper>
      <br />
      <div>
        With the growth of decentralized network, I was attracted by the blockchain technology that ensures both security and convenience.
        However, even with the research on details of such technology, I could not fully grasp the concept and the structure.
        Rather than being one of the readers of the information written by others, I wanted to be the one who could explain the whole details of blockchain technology.
        So I decided to learn how to become a blockchain developer; thanks to a bootcamp course, I was able to open up a new path to my future career.
        <br />
        <br />
        Other than blockchain, I also have high passion towards frontend development.
        The fact that I can see how a webpage changes as I write my code has shocked me in the beginning.
        The fact that I was actually changing the design of the webpage just by writing some codes was enough to mesmerize me.
        That feeling of achievement was something that I have never felt before; it was something much larger than the moment I was accepted to college or the moment I received an award at a soccer tournament.
        There are still a lot to learn and that's another reason that I really want to go for frontend developer.
        I think I will never stop learning; I think I will never stop improving.
        And I think I will make a huge impact in this area.
      </div>
      <div className='w-full h-10'></div>
      {/* <div>
        <p className='text-lg font-bold'>Experience</p>
        <br />
        <ul>
          <li>
            <div className='w-full h-6 flex flex-row justify-start'>
              <img src='./skytradelinks_logo.png' alt='SkyTrade' className='w-5 h-5 justify-center items-center' />
              <p className='text-base font-semibold w-1/2 ml-3 flex justify-start items-start'>SkyTrade</p>
            </div>
            <div className='w-full h-6'>
              <p>Blockchain and Full Stack Engineer Intern</p>
            </div>
            <div className='w-full h-6'>
              <p className='text-sm text-gray-500'>October 2024 ~ present</p>
            </div>
          </li>
        </ul>
      </div> */}
    </Wrapper>
  )
}

export default AdditionalDetails;